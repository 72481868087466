/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:85538079-ce59-442b-8b9f-da4ab90d447d",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_ah7K28wTg",
    "aws_user_pools_web_client_id": "3ss6arqp6tbod3pd628ja36ljq",
    "oauth": {},
    "aws_user_files_s3_bucket": "wbldaeb665ee6a2481492db0bce07f88470112525-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://t4dc3by7lnaidd7zdvicgcpl7q.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tt5jcr4ofjgmtiveaj25eeeoey"
};


export default awsmobile;
