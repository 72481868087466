import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  newsPage: 1,
  clientNews: {
      nextTokens: [],
      data: null
  },
}

const changeState = (state = initialState, { type, ...rest }) => {
  // console.log({...state, ...rest })
  switch (type) {
    case 'set':
      console.log({...state, ...rest })
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store