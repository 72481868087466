import React from 'react';
import Amplify from "aws-amplify";
import config from "./aws-exports";
import {
     BrowserRouter as Router,
     Route,
} from "react-router-dom";
import './App.css'

const TheMain = React.lazy(() => import('./client/pages/main'));
const Dashboard = React.lazy(() => import('./admin/pages/dashboard'));

Amplify.configure(config);


const loading = (
     <div className="pt-3 text-center">
          <div className="sk-spinner sk-spinner-pulse"></div>
     </div>
)

const App = () => {

     let isAdmin = false;

     if(window.location.href.indexOf("/admin") > -1){
          isAdmin = true
     }

     return (
       <div className="App">
          <Router>
               <React.Suspense fallback={loading}>
                    {
                         isAdmin ?
                         <Route exact path="/admin" name="Admin" render={props => <Dashboard {...props}/>} /> : 
                         <Route path="/" name="Main" render={props => <TheMain {...props}/>} />
                    }
               </React.Suspense>
          </Router>
       </div>
     )
}

export default App;